<template>
  <div class="follow-item">
    <div class="item-title">
      <span class="title">{{ item.followDTO.followName }}</span>
      <span class="date"><span class="line">|</span>{{ createTime }}</span>
      <span class="status" :class="followStatusClass" v-if="followStatusText"><span class="line">|</span>{{ followStatusText }}</span>
    </div>
    <div class="item-desc" v-if="item.followDescription">{{ item.followDescription }}</div>
    <div class="error-message" v-if="item.reviewStatus == 3 && item.reviewComment">{{ item.reviewComment }}</div>
    <div class="item-footer van-hairline--bottom">
      <van-button type="primary" v-if="item.reviewStatus == 0" @click="clickFollow">填写<span class="red-drop" v-if="item.redDot"></span></van-button>
      <van-button type="primary" v-if="item.reviewStatus == 4" @click="clickFollow">提交</van-button>
      <van-button type="primary" v-if="item.reviewStatus == 3" @click="clickFollow">修改<span class="red-drop" v-if="item.redDot"></span></van-button>
      <van-button v-if="item.reviewStatus == 1 || item.reviewStatus == 2" @click="clickFollow">查看内容</van-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'followListItem',
    props: ['item'],
    computed: {
      createTime () {
        return this.item.createTime?.split(' ')[0].split('-').join('·')
      },
      followStatusText () {
        switch (this.item.reviewStatus) {
          case 2: return '已通过'
          case 3: return '未通过'
          default: return ''
        }
      },
      followStatusClass () {
        switch (this.item.reviewStatus) {
          case 2: return 'success'
          case 3: return 'error'
          default: return ''
        }
      }
    },
    methods: {
      clickFollow () {
        let formType = ''
        switch (this.item.reviewStatus) {
            case 3:
            case 4:
              formType = 'edit'
              break;
            case 0:
              formType = ''
              break;
            default: 
              formType = 'preview'
        }

        this.$router.push({
            path: `/i-surveys/form/${ this.item.id }`,
            query: {
              formType,
              isFollow: 1
            }
        })
      }
    }
  }
</script>