<template>
  <div class="i-surveys-follow">
    <div class="i-surveys-follow-main">
      <div class="answer-main van-hairline--bottom">
        <div class="title">
          <div class="status" :class="reviewStatusTag">{{ reviewStatus }}</div>
          <div class="title--inner van-ellipsis" v-if="answerDetail.surveyFormDTO">{{ answerDetail.surveyFormDTO.title }}</div>
        </div>
        <div class="desc">
          <div class="date">{{ updateDate }}</div>
          <div class="recognize-code" v-if="answerDetail.recognizeCode && answerDetail.reviewStatus"><span class="line">|</span>识别码：{{ answerDetail.recognizeCode }}</div>
        </div>
      </div>
      <div class="list_body">
        <van-list
            v-if="loading || total"
            :value="loading"
            :finished="finished"
            @load="loadMore"
        >
          <list-item
            v-for="item in list"
            :key="item.id"
            :item="item"
          ></list-item>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
  import listMixins from '@/mixins/list'
  import ListItem from './components/list-item.vue'
  export default {
    name: 'followList',
    mixins: [listMixins],
    components: { ListItem },
    data: () => ({
      unMounted: true,
      selectParams: {
        answerId: '',
        reviewStatus: ''
      },
      answerDetail: {}
    }),
    computed: {
      statusMap () {
        return this.$status.iSurveys
      },
      reviewStatusTag () {
        return this.statusMap.reviewStatusTag[this.answerDetail.reviewStatus]
      },
      reviewStatus () {
        return this.statusMap.reviewStatus[this.answerDetail.reviewStatus]
      },
      updateDate () {
        return this.answerDetail.updateTime?.split(' ')[0].split('-').join('·')
      },
      apiSelect () {
        return this.$api.surveys.followPage
      }
    },
    created () {
      this.$set(this.selectParams, 'answerId', this.$route.params.answerId)
      this.getAsnwerDetail()
    },
    methods: {
      getAsnwerDetail () {
        this.$api.surveys.asnwerDetail(this.$route.params.answerId).then(res => {
          if (!res.code) {
            this.answerDetail = res.data
            this.getDataPage();
          }
        })
      }
    }
  }
</script>